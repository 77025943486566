*{
    margin: 0;
    padding: 0;
  }
  *::selection {
    background-color: $theme-color;
    color: $light-color;
  }
  
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  :where(.css-tpassh).ant-tabs-card >.ant-tabs-nav .ant-tabs-tab-active{
    background-color: #5c7a93 !important;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: $theme-color;
    border-radius: 10px;
    border: 2px solid transparent;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: $theme-color;
  }
  

  * {
    scrollbar-width: thin;
    scrollbar-color: $theme-color transparent;
  }
:where(.css-dev-only-do-not-override-11lehqq).ant-switch.ant-switch-checked{
    background:$theme-color !important;
  }
  :where(.css-dev-only-do-not-override-11lehqq).ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
    background: $theme-color !important;
    border-color: $theme-color !important;
  }

  .ant-typography{
    margin: 0 !important;
  }
  .create-button{
    background-color: $theme-color;
    color: $light-color;
  }
  .edit-button{
    margin-left: 30px;
  }
  .remove-permission{
    background-color: red !important;
    margin-left: 8px;
  }
  .create-user{
    float:right;
    margin-top: 25px;
  }
  .create-button-content{
    text-align: right;
  }
  .text{
    color: $theme-color;
  }
  .border{
    border: 1px solid $theme-color;
    border-radius: 5px;
  
  }
  .custom-outlined-btn {
    border: 1px solid $theme-color; 
    background: transparent;
    color: $theme-color;
  }
  
  .custom-outlined-btn:hover {
    border: 1px solid rgb(112 111 111) !important; 
    background: transparent;
    color: $light-color;
  }
  .search{
    border-radius: 34px !important;
    margin-bottom: 0;
  }
  .headings{
    color: $theme-color !important;
    padding-bottom: 20px;
  }
  :where(.css-dev-only-do-not-override-11lehqq).ant-checkbox-checked .ant-checkbox-inner{
background-color: $theme-color;
border-color: $theme-color ;
  }

  .search  .ant-input-outlined{
    border-radius: 50px;
  }

  .ant-table-thead .ant-table-cell{
    background-color: $light-gray !important;
  }
.ant-breadcrumb ol{
 justify-content: end;
}
  .text{
   color:$theme-color !important;
  }
  .ant-layout{
    background: #f5f5f5;//#f5f5f5; //#e7eef3
}
.main-sidebar {
  position: fixed;
  height: 100vh;
}

 .ant-input{
  background: $light-gray;
  border-color: $light-gray;
 }
.text-input-ric .ant-input{
  background : white !important;
}
 .eye svg{
  fill:rgb(109, 109, 109);
  width: 24px;
  height: 24px;
 }

 .copy svg{
  fill:rgb(109, 109, 109);
  width: 24px;
  height: 24px;
 }

 .feature-button{
  text-align:right;
  margin-top:30px;
  padding: 12px;
 }

 .feature-button .ant-btn {
 background-color: $theme-color !important;
 color: $light-color;
 &:hover{
  background-color: rgb(112 111 111) !important;
  color: $light-color !important;
  border-color: rgb(112 111 111) !important;
 }
 }

 .login-btn{
  background-color: $theme-color !important;
  color: $light-color;
  border-radius: 40px;
  padding-left: 64px;
  padding-right: 64px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  border: none;
  &:hover{
    background-color: $dark-grey !important;
    color: $light-color !important;

  }
 }

 .close-icon{
  position: absolute;
  right: 10px;
  top: 22px;
  display: none;

 }

 .close-icon button{
  background: #5c7a93;
  color: white;

 }
 .ant-switch-checked {
 
  &:hover{
    background: #5c7a93 !important;
  }
  &[role="switch"] {
    background: #5c7a93;
    &:hover{
      background: #5c7a93 !important;
    }
  }
}
 @media only screen and (max-width: 767px) {
  .close-icon {
  display: block;
  }
   .ant-select-show-search{
    min-width: 100% !important;
    max-width: 100% !important;
  }
}
@media only screen and (max-width: 1024px) {

   .ant-select-show-search{
    min-width: 100% !important;
    max-width: 100% !important;
  }
}
.ric-table .ant-table-thead .ant-table-cell{
  background-color: #5c7a93 !important;
  color: white !important;
}
.ric-table :where(.css-dev-only-do-not-override-tpassh).ant-table-wrapper .ant-table-column-sorter-up.active,:where(.css-dev-only-do-not-override-tpassh).ant-table-wrapper .ant-table-column-sorter-down.active {
  color: white !important;
}
.faq-collapse {
  background: transparent;
}
.faq-panel {
  border: none;
}
.faq-answer {
  padding: 8px 16px;
  color: #666;
}
.ant-collapse-header {
  padding: 8px 0 !important;
}
.ant-btn-variant-solid{
  background-color: #5c7a93 !important;
}
.ant-tabs-tab-active{
  background-color: #5c7a93 !important;
  color: white !important;
}
.ant-tabs-tab-active .ant-tabs-tab-btn{
  background-color: #5c7a93 !important;
  color: white !important;
}
.ant-tabs-tab-active  .ant-tabs-nav .ant-tabs-tab{
  background-color: #5c7a93 !important;
  color: white !important;
}
.ant-tabs-nav .ant-tabs-tab{
  background-color:white !important;
  color:  #5c7a93 !important;
}
:where(.css-dev-only-do-not-override-tpassh).ant-tabs-card >.ant-tabs-nav .ant-tabs-tab-active, :where(.css-dev-only-do-not-override-tpassh).ant-tabs-card >div>.ant-tabs-nav .ant-tabs-tab-active{
  background-color: #5c7a93 !important;
  color: white !important;
}
.iframe {
  text-align: center !important;
}
.iframe iframe{
  text-align: center;
  height: 450px;
  border-image: none !important;
  border: none;
  width: 100%;
  text-align: center !important;
}
.first-frame iframe{
  height: 385px;
  border-image: none !important;
  border: none; width: 100%;
}